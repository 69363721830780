/* @font-face {
    font-family: 'Blaster';
    font-style: normal;
    font-weight: 400;
    src: 
    local('SF'), 
    url('./fonts/Blaster.otf'), format('opentype');
} */
/* @import url("https://fonts.googleapis.com/css2?family=Goudy+Bookletter+1911&display=swap"); */

.Hero {
    /* height: fit-content; */
    height: 30vh;
    justify-content: center;
    /* align-items: center; */
    align-content: space-around;
    /* background: linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.4)); */
    background-image: linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.2)), url('./assets/IMG_0339.jpg');
    /* height: 640px; */
    background-size: cover;
    /* background-size: 100%; */
    background-position: center;
    background-repeat: no-repeat;
    /* flex: 'display'; */
    flex-direction: column;
    display: grid;
    margin-top: 2vh;
    margin-bottom: 2vh;
    margin-left: 2%;
    margin-right: 2%;
}
.working{
        height: 90vh;
        justify-content: center;
        /* align-items: center; */
        align-content: space-around;
        /* background: linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.4)); */
        background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.2)), url('./assets/IMG_0339.jpg');
        /* height: 640px; */
        background-size: cover;
        /* background-size: 100%; */
        background-position: center;
        background-repeat: no-repeat;
        /* flex: 'display'; */
        flex-direction: column;
        display: grid;
        margin-top: 2%;
        margin-bottom: 2%;
        /* margin-left: 2%;
        margin-right: 2%; */
}
@media screen and (max-width: 600px) {
    /* .container{
        margin: 3em,
    } */
    .checkiout_button{
        width: 100%;
        z-index: 1;
        /* margin: 3em; */
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: '#f8f6f1';
    }
}